<template>
	<div>
		<!-- Header -->
		<b-container class="h-100vh d-flex mt-7 justify-content-center">
			<div>
				<b-row class="justify-content-center">
					<img
						src="../../../../client/src/assets/Logo/ComodeIcon.svg"
						class="logo"
						style="cursor: pointer"
						@click="$router.push('/adminApp/login')"
					/>
				</b-row>
				<div class="d-flex justify-content-center screen-title">メールアドレス認証</div>
				<b-row class="justify-content-center">
					<b-col>
						<card class="w-100 my-0 text-center">
							<div class="elm-title">登録したメールを確認してください。</div>
							<div class="elm-title mt-2">
								メール内のURLから登録手続きを完了してください。
							</div>
							<div class="direct-link-underline mt-5" @click="reSendEmail">
								確認メールを再送する
							</div>
							<div v-show="showMessage" id="message">
								{{ message }}
							</div>
						</card>
					</b-col>
				</b-row>
			</div>
		</b-container>
	</div>
</template>

<script>
import UserService from '../../services/user.service';
export default {
	components: {},
	name: 'registerWaiting',
	data() {
		return {
			showMessage: false,
			message: '',
		};
	},
	methods: {
		async reSendEmail() {
			var email_address = this.$route.query.email_address;
			if (email_address) {
				console.log(email_address);
				let [response, error] = await await UserService.resendVerifyEmail(
					email_address
				);
				if (error) {
					this.message = error.message;
				} else {
					this.message = response.message;
				}
				this.showMessage = true;
			}
		},
	},
};
</script>
